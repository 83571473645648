import React, {Component} from 'react';

class Patrick extends Component{

    render(){

        return ( 
            
        <section class="team">

        <div class="container info__container">

            <article class="team__member">

                <div class="team__member-image-details">

                    <img className="heroImage" alt = "patrick" src =  "../images/PATRICK NYANGU.jpg" />

                </div>

                <div class="team__member-info">

                    <h4> PatricK Nyangu </h4>

                    <p> Director, Agriculture products, Logistics and Supply Chain Management </p>


                
                </div>

       
            
            </article>


            <div class = "details-info">    
                 <h2>Information</h2>


                 <p className = "text-justify">

                
                 Mr Patrick Nyangu has 25 years of experience in the agriculture and supply chain management. His expertise leads his other interests in Mayfield import and export as well as Nicemore Investment Ltd which are solely involved in the Import and Export of miscellaneous goods between Zambia and the rest of the World. He is our go to liaison in the areas of Agricultural produce and its required logistical nuances to ensure you get the produce you need, when you need it, in the correct condition and at the optimum price.


        
        
        
                   </p>
        
                
           </div>
            
         
            


        </div>


    </section>);
    
    }
}

export default Patrick;