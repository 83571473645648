import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {



    render() {

        return (
            <nav>

                <div className="container nav__container">

                    <Link to=""> <img className="logo heroImage" alt="here" src="images/logo2.png" />  </Link>

                    <ul className="nav__menu">

                        <li> <Link to="">Home</Link> </li>
                        <li> <Link to="/about-us"> About Us</Link> </li>
                        <li> <Link to="/our-team"> Our Team</Link> </li>
                        {/* <li> <Link to="/gallery"> Gallery </Link> </li> */}
                        <li> <Link to="/commitments"> Our Commitments</Link> </li>
                        <li> <Link to="/contact-us"> Contact</Link> </li>

                        <li> <a href="https://www.linkedin.com/company/metron-south-corporation-ltd/" rel="noreferrer" target="_blank"><img className="linkedin" alt="linkedin" src="images/link.png" /></a> </li>


                    </ul>

                    <button id="open-menu-btn" onClick={() => {

                        const menu = document.querySelector(".nav__menu");
                        const menuBtn = document.querySelector("#open-menu-btn");
                        const closeBtn = document.querySelector("#close-menu-btn");

                        menu.style.display = "flex";
                        closeBtn.style.display = "inline-block";
                        menuBtn.style.display = "none";


                    }} ><i className="uil uil-bars"></i></button>

                    <button id="close-menu-btn" onClick={() => {

                        const menu = document.querySelector(".nav__menu");
                        const menuBtn = document.querySelector("#open-menu-btn");
                        const closeBtn = document.querySelector("#close-menu-btn");

                        menu.style.display = "none";
                        closeBtn.style.display = "none";
                        menuBtn.style.display = "inline-block";

                    }}><i className="uil uil-multiply"></i></button>

                </div>

            </nav>);
    }






}


export default Navbar;