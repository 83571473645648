import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Home extends Component {

    render() {

        return (

            <header>

                <div className="container">

                    <div className = "header__container">


                    <div className="header__left">

                        <h1>We are Metron</h1>

                        <p className="text-align-left">Metron South Corporation Ltd - Two primary areas of business:
                        </p>

                        <Link to="about-us" class="btn btn-primary"> Read More </Link>

                    </div>


                    <div className="header__right">


                        <div className="header__right-image">

                            <img alt="here" className="heroImage" src="images/artyom-korshunov-NWByxwVN-J0-unsplash.jpg" />

                        </div>

                    </div>

                    </div>


                    <div class="container_head">

                        <h4 > Metron South Corporation Ltd subscribes to and supports </h4>

                        <img className="goalImage" src="images/download.png" alt="sustainable goals" />

                    </div>

                  

                </div>







            </header>
        );
    }
}


export default Home;