import React, {Component} from 'react';

class Kaluba extends Component{

    render(){

        return ( 
            
        <section class="team">

        <div class="container info__container">

            <article class="team__member">

                <div class="team__member-image-details">

                    <img className="heroImage" alt = "kaluba" src =  "../images/kaluba.jpg" />

                </div>

                <div class="team__member-info">

                    <h4>Kaluba Nyangu</h4>

                    <p>Business Manager </p>


                
                </div>

       
            
            </article>


            <div class = "details-info">    
                 <h2>Information</h2>


                 <p className = "text-justify">

                 Kaluba Nyangu has 7 years retail banking experience with special focus in customer service and branch operations.  She has a bachelor’s Degree in Economics from the University of Namibia and currently pursuing a master’s degree in Economics and Finance from the University of Lusaka.
                 <br/>Ms Nyangu is enthusiastic and self-motivated; her ability to work well with others helps her in the effective execution of duties hence able to meet set objectives.
She is passionate about customer service and believes that every business should take keen interest in how it delivers its services as this is cardinal to building the image of an organisation.


        
                   </p>
        
                
           </div>
            
         
            


        </div>


    </section>);
    
    }
}

export default Kaluba;