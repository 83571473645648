import React, {Component} from 'react';
import emailjs from 'emailjs-com';


class Contact extends Component{


    constructor(props){
        super(props);
        this.state = {
            sending: false,
            sent : false
        }
        this.sendEmail = this.sendEmail.bind(this);
    }


    sendEmail(e){
        e.preventDefault();
        this.setState({sending:true});

        emailjs.sendForm('service_ixs0gds', 'template_2exvqy7', e.target, '4kML0DQSgHkqEFh0d').then((response) =>{
            console.log(response);
            this.setState({sending:false});
            this.setState({sent:true});
            
        }).catch((error) => {
            this.setState({sending:false});
            this.setState({sent:false});
            console.log(error);
        })
      
    }


    render(){

        const sending = this.state.sending;
        const sentEmail = this.state.sent;

        var send;
        var sent;

        if(sending){
           send = <h4 className = "send">Sending email....</h4>;
        }

        if(sentEmail){
            sent = <h4 className = "send"> Email Sent </h4>;
         }
 

        return (
            <section class="contact-us">


            <div class="container contact-us-container">
    
    
    {/** 
    
                <div class="left-side">
    
    
                    <div class = "contact">
    
                        <span class="lcon">
    
                           <i class = "uil uil-location-point"></i>
    
                        </span>
    
                        <p>123 Main Street
                            Unit 12
                            Lusaka, IL 12345</p>
    
                    </div>
    
    
                    <div class = "contact">
    
                        <span class="lcon">
    
                           <i class = "uil uil-location-point"></i>
    
                        </span>
    
                        <p>123 Main Street
                            Unit 12
                            Lusaka, IL 12345</p>
    
                    </div>
    
    
                    <div class = "contact">
    
                        <span class="lcon">
    
                           <i class = "uil uil-location-point"></i>
    
                        </span>
    
                        <p>123 Main Street
                            Unit 12
                            Lusaka, IL 12345</p>
    
                    </div>
    
    
    
                </div>
                
    */}
                <div class="right-side">
    
    
                    <h3> Send Us a Message </h3>
    
    
    
                    <form onSubmit={this.sendEmail}>
    
    
                        <input type = "text" name = "name" placeholder  ="Enter your name" />
    
                        <input type = "text" name= "user_email" placeholder = "Enter your email" />
    
                        <textarea rows="4"   name = "message" placeholder = "Enter your message"></textarea>
                       
                         {send}
                         {sent}

                        <button type = "submit"  class = "btn btn-primary">Submit</button>
    
                    </form>
    
    
    
    
    
                </div>
    
    
    
    
    
                </div>
    
    
         
    
    
    
    
        </section>
    
        );
    }
}

export default Contact;