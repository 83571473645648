import React ,{ Component } from "react";

class Gallery extends Component{


    render(){

        return(
            <div className="container">


             <div className='container_gallery'>

                <div className='image_content'>

                <img src="../images/Tanzanite 3.jpeg" alt='Tanzanite' />

                <h4>Tanzanite</h4>
                    
                </div>   

                <div className='image_content'>

                <img src="../images/Tanzanite.jpeg" alt='Tanzanite 2' />


                <h4>Tanzanite</h4>

                </div>        



                <div className='image_content'>

                <img src="../images/Tanzanite 2.jpeg" alt='Tanzanite 2' />

                
                <h4>Tanzanite</h4>
                    
                </div>        


                <div className='image_content'>

                <img src="../images/Citreen.jpeg" alt='Citreen' />

                
                <h4>Citreen</h4>
                    
                </div> 

                    <div className='image_content'>

                <img src="../images/Citreen2.jpeg" alt='Citreen' />

                
                <h4>Citreen</h4>
                    
                </div>  


                    <div className='image_content'>

                <img src="../images/Citreen3.jpeg" alt='Citreen' />

                
                <h4>Citreen</h4>
                    
                </div>   

                    <div className='image_content'>

                <img src="../images/Emeralds.jpeg" alt='Emeralds' />

                
                <h4>Emeralds</h4>
                    
                </div>

                    <div className='image_content'>

                <img src="../images/Amethyst.jpeg" alt='Amethyst' />

                
                <h4>Amethyst</h4>


                    
                </div>

                <div className='image_content'>

                    <img src="../images/Amethyst2.jpeg" alt='Amethyst' />


                    <h4>Amethyst</h4>


                    
                    </div>

                    <div className='image_content'>

                    <img src="../images/Amethyst1.jpeg" alt='Amethyst' />


                    <h4>Amethyst</h4>


                    
                    </div>

                <div className='image_content'>

                <img src="../images/Manganese.jpeg" alt='Manganese' />


                <h4>Manganese</h4>

                </div>

                                        


                <div className='image_content'>

                <img src="../images/Ferro Manganese.jpeg" alt='Ferro Manganese' />

                
                <h4>Ferro Manganese</h4>
                    
                </div>


                <div className='image_content'>

                <img src="../images/FManganese.jpeg" alt='FManganese' />

                
                <h4>Ferro Manganese</h4>
                    
                </div>



                <div className='image_content'>

                <img src="../images/Sugilite.jpeg" alt='Sugilite' />

                
                <h4>Sugilite</h4>
                    
                </div>    

                <div className='image_content'>

                <img src="../images/Copper Malachite.jpeg" alt='Copper Malachite' />

                
                <h4>Copper Malachite</h4>
                    
                </div>  


                <div className='image_content'>

                <img src="../images/Clear quartz.jpeg" alt='Clear quartz' />

                
                <h4>Clear quartz</h4>
                    
                </div>  




                <div className='image_content'>

                <img src="../images/Rutile quartz2.jpeg" alt='Rutile quartz' />

                
                <h4>Rutile quartz</h4>
                    
                </div>  


                <div className='image_content'>

                <img src="../images/Rutile quartz.jpeg" alt='Rutile quartz' />

                
                <h4>Rutile quartz</h4>
                    
                </div>  


                <div className='image_content'>

                <img src="../images/Blue sky or aquarmauline.jpeg" alt='Blue sky or aquarmauline' />

                
                <h4>Blue sky or aquarmauline</h4>
                    
                </div>  


                <div className='image_content'>

                <img src="../images/Various colors of tourmaline.jpeg" alt='Various colors of tourmaline' />

                
                <h4>Tourmaline</h4>
                    
                </div>                       







                </div>



            </div>
        )
    }



}

export default Gallery;