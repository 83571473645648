import React, {Component} from 'react';

class About extends Component{

    render(){

        return (

            <div>

            <section class="about__achievements">

            <div class="container about-us-container">
    
            
                <div class="about-us-heading">
                    
                    <h1>About Us</h1>
    
                    <p> Metron South Corporation Ltd – Two primary areas of business: </p>
    
                </div>
    
            </div>
    
    
        </section>
    


            <section class="about-us">
        
        
                <div class="container about__container">
        
                   <div class = "details-about">
        
                      <h3> Industrial </h3>
        
        
                      <p> Our industrial business spans the metals and energy markets. Offering a more credible access to the extractive industry and promoting efficient, clean alternative energy. </p>
                       
        
        
                   </div>
        
        
                   <div class = "details-about">
        
                    <h3> Marketing </h3>
        
        
        
                      <p> We move commodities and Agric produce from where they are plentiful to where they are most needed
        
                        Copper, zinc, manganese, Fluorite, Feldspar, cobalt, lead, gold, and emerald: these stunning metals are some of the underlying building blocks of our modern life – and indeed our future. Journey with us to discover their story and how we work to deliver them safely and reliably. 
                        <br/>In today’s world of economies of scale and comparative advantages it is critical to have a partner who understands the undulating needs and fluidity of global markets to your bottom line. Metron understands the frustrations that are inherent in doing international business transactions within and across our industry and we have traversed many plains so that you don’t have to. This enables us to refine our offering to you.
                        Metron South Corporation Limited exists to ease your mind and keep your focus on key performance indicators.
                        A bespoke product origination and delivery outfit domiciled in Zambia, Metron South Corporation Limited is here to assist you with the critical components of delivering ethically extracted and professionally processed metals and Agric produce. 
                        <br/>Our key differentiating factor is we come into every relationship with a clear understanding of what it takes to deliver our promise with integrity based on over 80 years of combined business experience from our team. 
                        Metron is committed to deriving optimum yield from value chains in fostering produce transformation from primary, secondary, and tertiary stages whilst promoting industry best practices at each stage. 
                         </p>
                       
        
        
                   </div>
        
                     
        
        
                 </div>
        
        
            </section>

            </div>
        );
    }
}

export default About;