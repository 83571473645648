import React, {Component} from 'react';

class Natalie extends Component{

    render(){

        return ( 
            
        <section class="team">

        <div class="container info__container">

            <article class="team__member">

                <div class="team__member-image-details">

                    <img className="heroImage" alt = "natalie" src = "../images/IMG-20220405-WA0002.jpg" />

                </div>

                <div class="team__member-info">

                    <h4>Natalie Mufalo</h4>

                    <p>Chief Executive Officer</p>


                
                </div>



            
            </article>


            <div class = "details-info">    
                 <h2>Information</h2>


                 <p className = "text-justify">


                 Ms Natalie Mufalo is a mobilization specialist with a keen interest in project liaison management between communities and enterprises with an emphasis on the promotion of women’s involvement in downstream economic activity especially in mining and its related industries.<br/> The founder of GLOW Allance Zambia she has extensive knowledge in symbiotic resource utilization in ensuring that the utilization of natural resources does not in any way bring either environmental or socio-economic harm to host communities. In addition, Natalie has exposure to the areas of Mine and Quarry Management, Environmental Health, Safety and Community relation as well as market analysis and investment promotion in the mineral developmental sector. She will utilise her administrative intellect  and passion for the african mineral sector to lead Metron South Corporation Ltd as Chief Executive Officer

        
                   </p>
        
                
           </div>
            
         
            


        </div>


    </section>);
    
    }
}

export default Natalie;