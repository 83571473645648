import React, {Component} from 'react';

class Sengu extends Component{

    render(){

        return ( 
            
        <section class="team">

        <div class="container info__container">

            <article class="team__member">

                <div class="sengu-image">

                    <img className="sengu-hero" alt = "sangu" src =  "../images/sangu.jpg" />

                </div>

                <div class="team__member-info">

                    <h4>  Saunak Sengupta</h4>

                    <p> Legal director (non executive) </p>
                
                </div>

       
            
            </article>


            <div class = "details-info">    
                 <h2>Information</h2>


                 <p className = "text-justify">

                 Saunak Sengupta, a legal practitioner primarily in the spheres of arbitration, company law and other civil laws. Has been practicing law for over 12 years. Having extensive knowledge in trading of commodities being minerals, metals and agricultural produce in the Asian market.

        
        
                   </p>
        
                
           </div>
            
         
            


        </div>


    </section>);
    
    }
}

export default Sengu;