import React, {Component} from 'react';

class Moffat extends Component{

    render(){

        return ( 
            
        <section class="team">

        <div class="container info__container">

            <article class="team__member">

                <div class="team__member-image-details">

                    <img className="heroImage" alt = "willie" src =  "../images/moffat.jpeg" />

                </div>

                <div class="team__member-info">

                    <h4> Moffat Mukwala </h4>

                    <p> Director, Mining operations, New Mine acquisitions and environment </p>


                
                </div>

       
            
            </article>


            <div class = "details-info">    
                 <h2>Information</h2>


                 <p className = "text-justify">


                 Moffat Mukwala
Mr Moffat Mukwala brings 25 years of emerald mining knowledge to MSCL. He is currently working as CEO of Gerntina Mines Limited, mining emeralds in the Lufwanyama Emerald Restricted Area of the Copper belt in Zambia.<br/> As one of the founders of Gerntina Mines incorporated in Minnesota USA, his rich research in gemstone mining, marketing and industry worldwide, is best described as unequalled.  
In addition to the foregoing, he has also worked for Zambian conglomerates such as Zambia Breweries and Niec Stores Limited adding up to over 35 years of working experience. His mission with MSCL is to tap into the growing Green Energy, Green Economy, and Clean Energy opportunities currently present globally.
              
              
        
                   </p>
        
                
           </div>
            
         
            


        </div>


    </section>);
    
    }
}

export default Moffat;