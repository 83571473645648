import React, {Component} from 'react';

class Nikolai extends Component{

    render(){

        return ( 
            
        <section class="team">

        <div class="container info__container">

            <article class="team__member">

                <div class="team__member-image-details">

                    <img className="heroImage" alt = "willie" src =  "../images/NIKOLAI MASONI.jpg" />

                </div>

                <div class="team__member-info">

                    <h4>Nikolai Masoni</h4>

                    <p>Director, Business Development and Product Rebranding </p>


                
                </div>

       
            
            </article>


            <div class = "details-info">    
                 <h2>Information</h2>


                 <p  className = "text-justify">

                 Mr Nikolai Masoni is a business development and marketing professional with 26 years of experience in setting up Special Purpose vehicles in Nigeria and the United Kingdom. His skillset is in the areas of consulting, market penetration, stakeholder management, identification and maximization of growth opportunities whilst managing client expectations. <br/>He is presently part of an international consortium delivering market access to firms in 40 countries across 4 continents.
He shall bringing his skillset to MSCL to assist in turnkey solutions in the area of Business Development and Product Sourcing and Rebranding.



        
        
        
                   </p>
        
                
           </div>
            
         
            


        </div>


    </section>);
    
    }
}

export default Nikolai;