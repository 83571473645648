import React, {Component} from 'react';

class Refresh extends Component{

    render(){

        return ( 
            
        <section class="team">

        <div class="container info__container">

            <article class="team__member">

                <div class="refresh-image">

                    <img className="heroImage" alt = "willie" src =  "../images/chapula.jpeg" />

                </div>

                <div class="team__member-info">

                    <h4>Refresh Chapula</h4>

                    <p> Director, Human resources, logistics and supply chain management  </p>
                
                </div>

       
            
            </article>


            <div class = "details-info">    
                 <h2>Information</h2>


                 <p className = "text-justify">

                 Mr Refresh Chapula has over 10 years’ experience serving as the sales coordinator of one of Zambia’s biggest manufacturers. An accountant by training he is involved with Metron South Corporation where he will be steering the HR, Logistics and Supply chain management aspects of the company. <br/> His overall belief is that MSCL synergies need to be harnessed for maximum profitability hence his interest in assisting with Human Resources combined with providing us with a firm platform in Logistics and Supply chain management.

        
        
                   </p>
        
                
           </div>
            
         
            


        </div>


    </section>);
    
    }
}

export default Refresh;