import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Team extends Component {

    render() {

        return (

            <section class="team">

                <h2 class="teamH1">Meet Our Team</h2>


                <div class="container team__container">

                    <article class="team__member">

                        <div class="team__member-image">

                            <img className="heroImage" src="./images/IMG-20220405-WA0002.jpg" />

                        </div>

                        <div class="team__member-info">

                            <h4>Natalie Mufalo</h4>

                            <p>Chief Executive Officer</p>




                        </div>



                        <div class="details">

                            <Link class="btn btn-primary" to="natalie">View Details</Link>

                        </div>



                    </article>


                    <article class="team__member">

                        <div class="team__member-image">

                            <img className="heroImage" alt="willie" src="./images/willie.jpg" />

                        </div>

                        <div class="team__member-info">

                            <h4>Willie Mambwe</h4>

                            <p>Company Chairman</p>

                        </div>




                        <div class="details">

                            <Link class="btn btn-primary" to="willie">View Details</Link>

                        </div>


                    </article>



                    <article class="team__member">

                        <div class="team__member-image">

                            <img alt="masoni" className="heroImage" src="./images/NIKOLAI MASONI.jpg" />

                        </div>

                        <div class="team__member-info">

                            <h4>Nikolai Masoni</h4>

                            <p>Director, Business Development and Product Rebranding</p>

                        </div>





                        <div class="details">

                            <Link class="btn btn-primary" to="nikolai">View Details</Link>

                        </div>


                    </article>



                    <article class="team__member">

                        <div class="team__member-image">

                            <img className="heroImage" src="./images/PATRICK NYANGU.jpg" />

                        </div>

                        <div class="team__member-info">

                            <h4>Patrick Nyangu</h4>

                            <p>Director, Agriculture products, Logistics and Supply Chain Management</p>

                        </div>


                        <div class="details">

                            <Link class="btn btn-primary" to="patrick">View Details</Link>

                        </div>


                    </article>



                    <article class="team__member">

                        <div class="team__member-image">

                            <img className="heroImage" src="./images/moffat.jpeg" />

                        </div>

                        <div class="team__member-info">

                            <h4>Moffat Mukwala</h4>

                            <p>Director, Mining operations, New Mine acquisitions and environment</p>

                        </div>




                        <div class="details">

                            <Link class="btn btn-primary" to="moffat">View Details</Link>

                        </div>


                    </article>



                    <article class="team__member">

                        <div class="team__member-image">

                            <img className="heroImage" src="./images/kaluba.jpg" />

                        </div>

                        <div class="team__member-info">

                            <h4>Kaluba Nyangu</h4>

                            <p>Bussiness Manager</p>

                        </div>


                        <div class="details">

                            <Link class="btn btn-primary" to="kaluba">View Details</Link>

                        </div>


                    </article>




                    <article class="team__member">

                        <div class="team__member-image">

                            <img className="heroImage" src="./images/chapula.jpeg" />

                        </div>

                        <div class="team__member-info">

                            <h4>Refresh Chapula</h4>

                            <p> Director, Human resources, logistics and supply chain management </p>

                        </div>


                        <div class="details">

                            <Link class="btn btn-primary" to="refresh">View Details</Link>

                        </div>


                    </article>




                    <article class="team__member">

                        <div class="team__member-image">

                            <img className="heroImage" src="./images/sangu.jpg" />

                        </div>

                        <div class="team__member-info">

                            <h4>Saunak Sengupta</h4>

                            <p>Legal director (non executive) </p>

                        </div>


                        <div class="details">

                            <Link class="btn btn-primary" to="sengu">View Details</Link>

                        </div>


                    </article>






                </div>


            </section>);
    }
}

export default Team;