import React, {Component} from 'react';

class Willie extends Component{

    render(){

        return ( 
            
        <section class="team">

        <div class="container info__container">

            <article class="team__member">

                <div class="team__member-image-details">

                    <img className="heroImage" alt = "willie" src =  "../images/willie.jpg" />

                </div>

                <div class="team__member-info">

                    <h4>Willie Mambwe</h4>

                    <p>Company Chairman, Finance- Investment and Banking </p>


                
                </div>

       
            
            </article>


            <div class = "details-info">    
                 <h2>Information</h2>


                 <p className = "text-justify">

                 Mr Willie Mambwe is a member of the Chartered Institute of Procurement and supply with extensive linkages and experience in maritime, port and intermodular logistics with an emphasis in the sourcing of maintenance parts and managing of supply chains.<br/> He has vast leads in the United Kingdom’s International Trade Investments, brokerage of business between Europe and the Southern African Subcontinent.
Additionally, Mr Mambwe has worked within the horticultural trade industry while a member of the Economic Association of Zambia. He brings to MSCL a depth of knowledge in the industry whilst also doubling up as our inhouse Finance Investment and Banking nerve centre as Chairman of the Board.



        
        
        
                   </p>
        
                
           </div>
            
         
            


        </div>


    </section>);
    
    }
}

export default Willie;