
import './App.css';
import Navbar from './components/Navbar';
import React, {Component} from 'react';
import { Route, Routes } from 'react-router-dom';
import  Home  from './components/Home';
import  Contact  from './components/Contact';
import Footer from './components/Footer';
import Team from './components/Team';
import Natalie from './components/Natalie';
import Willie from './components/Willie';
import Nikolai from './components/Nikolai';
import Patrick from './components/Patrick';
import Moffat from './components/Moffat';
import Kaluba from './components/Kaluba';
import Refresh from './components/Refresh';
import About from './components/About';
import Sengu from './components/Sengu';
import Commitments from './components/Commitments';
import Gallery from './components/Gallery';

class App extends Component {
  
  render(){
    return (
    
      <div>

          <Navbar />

          <div className = "content">

            <Routes>

               <Route path= "/" element = {<Home />} />
               <Route path= "/contact-us" element = {<Contact />} />
               <Route path= "/our-team" element = {<Team />} />
               <Route path= "/our-team/natalie" element = {<Natalie />} />
               <Route path= "/our-team/willie" element = {<Willie />} />
               <Route path= "/our-team/nikolai" element = {<Nikolai />} />
               <Route path= "/our-team/patrick" element = {<Patrick />} />
               <Route path= "/our-team/moffat" element = {<Moffat />} />
               <Route path= "/our-team/kaluba" element = {<Kaluba />} />
               <Route path= "/our-team/refresh" element = {<Refresh />} />
               <Route path= "/our-team/sengu" element = {<Sengu />} />
               <Route path= "/about-us" element = {<About />} />
               {/* <Route path= "/gallery" element = {<Gallery />} /> */}
               <Route path= "/commitments" element = {<Commitments />} />
               <Route path= "/contact-us" element = {<Contact />} />

            </Routes>
          

          </div>

          <Footer/>
      
      </div>

    );
  }


}

export default App;
