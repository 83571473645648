import React, { Component } from 'react';

class Commitments extends Component {

  render() {

    return (


      <div>



        <section class="container about__achievements">



          <div class="container_head">

            <h4 > Metron South Corporation Ltd subscribes to and supports </h4>

            <img className="goalImage" src="images/download.png" alt="sustainable goals" />

          </div>


          <div class="container commitments-container">

         
            <div class="commitments-heading">

              <h1>Our Commitments</h1>

              <h5> How Metron aims to deliver a sustainable future:</h5>

            </div>

          </div>


        
        </section>









        <section class="about-us">


          <div class="container commitments_container">

            <div class="details-about">

              <h3> Fighting against child labour  </h3>

      <p>
			<p> Metron South Corporation Ltd is devoted to the respect of human rights in line with the United Nations (UN) Guiding Principles on Business and Human Rights. We defend the dignity, fundamental freedoms and human rights of our people, communities and others potentially affected by our activities.
				<br/>
				<br/>Our purpose is to advocate, promote and defend respect for human rights within our company and all the way through our value chain to empower basic rights and freedoms. We will aim to:
				<br/>
		
			</p>
		</p>

              <ol>
                  <li>
                    - Counteract or allay harmful human rights infringement linked to our business activities, products, or services across all business relationships.

                  </li>

                  <li>
                    - Ensure positive contributions to the expansion of human rights of all people, including vulnerable groups. Should We drop the ball on this pledge, we partner with and offer support as we implement applicable solution.

                  </li>
                </ol>

            </div>


            <div class="details-about">

              <h3> Nourish and earn trust   </h3>



              <p>

                Ethics and compliance
                Metron South Corporation Ltd will nourish and earn trust of its clients and partners by being committed to and preserving a culture of ethics and compliance throughout its business.

                We will not merely carryout the minimum required by laws and regulations; we have set them as a guiding light for how we do business at Metron South Corporation Ltd. Therefore, we make a solemn pledge as follows:

                <ul>

                  <li>

                   - Metron South Corporation Ltd will not deliberately collude with any third party in breaking the law.

                  </li>

                  <li>
                   - Metron South Corporation Ltd will never participate in any criminal, fraudulent or corrupt practice in any country.

                  </li>
                </ul>

              </p>



            </div>

            <div class="details-about">

              <h3> Safety First   </h3>



              <p>

                At Metron South Corporation we have set as our primary priority the protection of the health and wellbeing of all our people – whether you work directly for us or are part of project. We are devoted to operating safely and are convinced that all accidents, work-related diseases, and injuries at work are avoidable.

              <br/> Our SafeZone framework is focused on the elimination of fatal accidents and critical injuries by detecting the hazards that may result in life threatening incidents and thereby designing and implementing life-saving habits and processes to eliminates them.

                It is our aim to not only provide everyone in our business with the knowledge but also the tools to carry out their assignments safely using SafeZone. Everyone at Metron South Corporation Ltd is empowered to put an end to unsafe work practices.




              </p>



            </div>

            <div class="details-about">

              <h3> The Challenge - Artisanal and small-scale mining (ASM)   </h3>



              <p>

                Metron South Corporation Ltd understands that artisanal and small-scale mining (ASM) is a major source of employment and income generation in many communities across Africa as well as other parts of the world, and we will champion and support efforts to improve the working conditions of legal ASM.<br/> We believe ASM can grow to become large0scale mines and deliver greater prosperity if carried out responsibly and transparently.

                We applaud efforts by responsible sourcing initiatives and international organisations to enhance methods and processes that deter the risks of human rights violations in this industry.

                Metron South Corporation Ltd is open to working with stakeholders to address the poverty that leads to ASM.

              </p>



            </div>



          </div>







        </section>

      </div>

    );
  }
}

export default Commitments;