import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component{

    render(){

        return (   <footer>

            <div class="container container__footer">
     
                <div class="footer__1">
     
                   <h3> Metron </h3>
     

                   <ul class = "permalinks">
     
                   <li> <Link to = "/"> Home</Link> </li>
   
   
     
             </ul>
     
                </div>
     
                <div class="footer__2">
     
                   <h3> Links </h3>
     
                     <ul class = "permalinks">
     
                         <li> <Link to = "/about-us"> About</Link> </li>
                                         
                     </ul>
               
     
                </div>
     
               {/*  <div class="footer__3">
     
                   <h4>Primary</h4>
     
                   <ul class="privacy">
     
                     {/* <li> <a href = "#"> Privacy Policy </a> </li>
                     <li> <a href = "#"> Terms and Conditions </a> </li>
                     <li> <a href = "#"> Refund Policy </a> </li>
                    
      
                   </ul>
     
                </div>

             */}
     
                <div class="footer__4">
     
                   <h3>Contact Us</h3>
     
                   <div>
     
                     {/* <p> 0963715641</p>
     
                     <p> sakalaern321@gmail.com</p> */}

                   <li> <Link to = "/contact-us"> Contact</Link> </li>
     
                   </div>
     
                   <ul class="footer__socials">
     
                      {/* <li> <a href = "#"> <i class="uil uil-facebook-f"></i> </a> </li>
     
                      <li> <a href = "#"> <i class="uil uil-instagram-alt"></i> </a> </li> */}
     
                   </ul>
     
     
                </div>
                
     
            </div>

           Created By Try Tech & Kinpin Arts
     
     
        </footer>);
    }
}

export default Footer;